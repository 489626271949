import { defaultAll, queryString } from "./lib/utils";
import {
  handlePricingInfoResponse,
  sendAPIFailureNotification
} from "./lib/responseHandlers";

const {
  REACT_APP_CREATE_API,
  REACT_APP_DRY_RUN,
  REACT_APP_NBUILD_START_URL,
  REACT_APP_SLUG_API,
  REACT_APP_PRICING_API
} = process.env;

const CREATE_TIMEOUT_WARNING_DELAY = 15000;
const CREATE_TIMEOUT = 25000;

export const CREATE_STATUS = {
  TIMEOUT_WARNING: 1,
  TIMEOUT: 2
};

const postUrlEncoded = (url, data) =>
  fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: queryString(data)
  });

export const getInitialNbuildData = sid =>
  fetch(`${REACT_APP_NBUILD_START_URL}?sid=${sid}`, {
    credentials: "include"
  }).then(response => response.json());

export const isSlugTaken = (slug, sid) =>
  validateSlug(slug, sid).then(response => {
    if (response.valid) {
      return false;
    } else if (
      response.error &&
      response.error.includes("already taken") /* barf */
    ) {
      return true;
    }
  });

export const validateSlug = (slug, sid, lang) =>
  postUrlEncoded(REACT_APP_SLUG_API, { slug, sid, lang }).then(response => {
    return response.text().then(text => {
      if (response.ok) {
        return JSON.parse(text);
      } else {
        return Promise.reject(
          JSON.stringify({
            headers: response.headers,
            status: response.status,
            type: response.type
          })
        );
      }
    });
  });

export const getTrialPricingInfo = async (language, nbuild, dispatch) => {
  let requestData = defaultAll({
    lang: language, // "en", "en-GB", "fr-CA", "fr-FR", or "es"
    months_count: nbuild.months_count, // data from nbuild for more pricing info
    package: nbuild.package_name,
    promo_id: nbuild.recruiter_id
  });

  postUrlEncoded(REACT_APP_PRICING_API, requestData)
    .then(response => {
      handlePricingInfoResponse(response, dispatch)
    })
    .catch(errors => {
      sendAPIFailureNotification("GetTrialPricingFailure", errors)
    });
};

export const submitNation = ({ userData: user, nbuild, network }) => {
  const data = defaultAll({
    // user data
    accept_tos: user.acceptTOS ? "1" : "0",
    country_code: user.country, // an ISO 3166-1 alpha-2 code, e.g. "US"
    currency: user.currency, // an ISO 4217 code, e.g. "USD"
    dry_run: REACT_APP_DRY_RUN,
    email: user.email,
    first_name: user.firstName,
    language: user.language, // "en", "en-GB", "fr-CA", "fr-FR", or "es"
    last_name: user.lastName,
    official_name: user.organizationName,
    payment_method: user.stripePaymentMethod,
    client_id: user.stripeClientId,
    phone: user.phone,
    phone_country_code: user.phoneCountryCode, // a number string, e.g. "1"
    slug: user.slug,
    state: user.state, // undefined unless country is US, a state abbreviation otherwise, e.g. "NY"
    tax_exempt: user.taxExempt, // boolean
    time_zone: user.timeZone, // a Rails-flavored time zone string, e.g. "Eastern Time (US & Canada)"
    use_case: user.useCase, // one of the options from nbuild/app/models/organizer_install.rb, e.g. "election"
    zip: user.zip, // undefined unless country is US, a 5-digit string or 9-digit string with hyphen otherwise, e.g. "00000" or "00000-0000"

    // Echo ID
    sid: network.sid,

    // data from nbuild
    cjevent: nbuild.cjevent,
    months_count: nbuild.months_count,
    package_name: nbuild.package_name,
    recruiter_id: nbuild.recruiter_id,
    signup_id: nbuild.signup && nbuild.signup.id,
    utm: nbuild.utm
  });

  return postUrlEncoded(REACT_APP_CREATE_API, data).then(response => {
    return response.text().then(text => {
      if (response.ok) {
        return JSON.parse(text);
      } else {
        return Promise.reject(
          JSON.stringify({
            headers: response.headers,
            status: response.status,
            type: response.type
          })
        );
      }
    });
  });
};

export const submitNationWithTimeout = (state, dispatch) => {
  const requestStartTime = Date.now();
  return new Promise((resolve, reject) => {
    let cancelled = false;

    // Set a timer to warn about potential timeouts.
    const warningTimer = setTimeout(() => {
      dispatch({
        type: "create-status",
        status: CREATE_STATUS.TIMEOUT_WARNING
      });
    }, CREATE_TIMEOUT_WARNING_DELAY);

    // Set a timer to actually time out the request
    const timeoutTimer = setTimeout(() => {
      cancelled = true;
      clearTimeout(warningTimer); // Clear warning timer when timing out
      resolve({ timeout: true, requestStartTime });
    }, CREATE_TIMEOUT);

    // Make the actual request to submit a nation.
    submitNation(state, dispatch).then(
      (response) => {
        // Clear both timers if we get a successful response
        clearTimeout(warningTimer);
        clearTimeout(timeoutTimer);
        if (!cancelled) {
          resolve(response);
        }
      },
      (...args) => {
        // If we've already cancelled then do nothing
        if (!cancelled) {
          clearTimeout(warningTimer);
          clearTimeout(timeoutTimer);
          reject(...args);
        }
      }
    );
  });
};
