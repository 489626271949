import { Field, Form, Formik } from "formik";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  allPhoneCodeOptions,
  getFirstPhoneCode,
  getCountryFromPCC
} from "../../lib/globalization";
import { defaultAll } from "../../lib/utils";
import { DisplayFormikState } from "../../helpers";
import FormInput from "../FormInput";
import FormRow from "../FormRow";
import ConnectedImageSidePanel from "../side-panels/ConnectedImageSidePanel";
import SubmitButton from "../SubmitButton";
import { useStateValue } from "../../hooks/state";
import { validateAccountForm } from "../../validate";

const Account = ({ fullWidth, saveStep, testId, title }) => {
  const [
    {
      userData: {
        country,
        phoneCountryCode,
        firstName,
        lastName,
        email,
        phone
      } = {}
    }
  ] = useStateValue();

  const { t } = useTranslation();

  const formatPhone = (phone, pcc) => {
    try {
      return parsePhoneNumberFromString(phone, getCountryFromPCC(pcc)).number;
    } catch (error) {
      console.log(error);
      return phone;
    }
  };

  return (
    <div
      data-testid={testId}
      className="row bg-white position-absolute w-100 min-vh-100 min-vh-100"
    >
      <main className="col-12 col-md-6 align-self-center ">
        <div
          className="mx-auto px-3 py-5"
          style={{ maxWidth: fullWidth ? "490px" : "560px" }}
        >
          <h1 className="mt-4 mt-md-5">{title}</h1>
          <Formik
            initialValues={defaultAll({
              firstName,
              lastName,
              email,
              phoneCountryCode: phoneCountryCode || getFirstPhoneCode(country),
              phone
            })}
            onSubmit={(values, actions) => {
              values.phone = formatPhone(values.phone, values.phoneCountryCode);
              saveStep(values);
            }}
            validate={validateAccountForm}
          >
            {props => {
              const {
                isSubmitting,
                isValidating,
                setFieldValue,
                setFieldTouched
              } = props;

              return (
                <Form>
                  <FormRow>
                    <Field
                      component={FormInput}
                      type="text"
                      name="firstName"
                      labelText={t("forms.labels.first_name")}
                      autoCorrect="off"
                      autoCapitalize="words"
                      autoFocus={true}
                      disabled={isSubmitting}
                      groupClasses="col-12 col-sm-6"
                    />
                    <Field
                      component={FormInput}
                      type="text"
                      name="lastName"
                      labelText={t("forms.labels.last_name")}
                      autoCorrect="off"
                      autoCapitalize="words"
                      disabled={isSubmitting}
                      groupClasses="col-12 col-sm-6"
                    />
                  </FormRow>
                  <FormRow>
                    <Field
                      component={FormInput}
                      type="email"
                      name="email"
                      labelText={t("forms.labels.email")}
                      disabled={isSubmitting}
                    />
                  </FormRow>
                  <FormRow classes="align-items-end">
                    <Field
                      component={FormInput}
                      type="select"
                      name="phoneCountryCode"
                      labelText={t("forms.labels.phone_country_code")}
                      disabled={isSubmitting}
                      options={allPhoneCodeOptions()}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      groupClasses="col-md-5 col-lg-4 col-xl-3"
                    />
                    <Field
                      component={FormInput}
                      type="phone"
                      name="phone"
                      labelText={t("forms.labels.phone")}
                      setFieldValue={setFieldValue}
                      country={getCountryFromPCC(props.values.phoneCountryCode)}
                      disabled={isSubmitting}
                    />
                  </FormRow>
                  <SubmitButton disabled={isSubmitting || isValidating} />
                  <DisplayFormikState {...props} />
                </Form>
              );
            }}
          </Formik>
        </div>
      </main>
      {fullWidth ? null : <ConnectedImageSidePanel category="support" random />}
    </div>
  );
};

Account.propTypes = {
  fullWidth: PropTypes.bool,
  saveStep: PropTypes.func.isRequired
};

export default Account;
