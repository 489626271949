import { handleAPIFailure } from "./lib/responseHandlers";
import { validateSlug } from "./api";
import { parse } from "psl";

const requiredError = "forms.errors.required";

export const validateCurrency = value => (value ? undefined : requiredError);
export const validateState = value => (value ? undefined : requiredError);
export const validateTimeZone = value => (value ? undefined : requiredError);

export const validateOrganizationName = value => {
  let error;
  if (!value) {
    error = requiredError;
  } else if (value.includes(";")) {
    error = "forms.errors.your_organization_name_should_not_include_semicolons";
  }
  return error;
};

export const validateZip = value => {
  let error;
  if (!value) {
    error = requiredError;
  } else if (!value.match(/^[0-9]{5}(?:-[0-9]{4})?$/)) {
    // zip code should match 00000 or 00000-0000
    error = "forms.errors.zip_format";
  }
  return error;
};

export const validateSlugChangeAndBlur = value => {
  let error;
  if (value.match(/\W|_/)) {
    error = "forms.errors.slug_alphanumeric";
  } else if (value.length > 30) {
    error = "forms.errors.slug_too_long";
  }
  return error;
};

export const validateSlugSubmission = ({ slug }, sid, lang, dispatch) => {
  const errors = {};
  errors.slug = slug ? validateSlugChangeAndBlur(slug) : requiredError;
  if (errors.slug) {
    return errors;
  }

  return validateSlug(slug, sid, lang)
    .then(response => {
      const errors = {};

      if (!response.valid && response.error) {
        errors.slug = response.error;
      } else if (!response.valid || (response.valid && response.error)) {
        // The slug is invalid but there's no recognized error, or the slug
        // is valid but there is an error.
        handleAPIFailure(
          "UnexpectedSlugValidationResponse",
          response,
          dispatch
        );
      }

      if (Object.keys(errors).length) {
        throw errors;
      }
    })
    .catch(errors => {
      if (errors.slug) {
        // Formik's async validation requires us to throw an errors object
        // with a property that matches the field name, e.g. `slug`. We need
        // this additional throw to ensure that a successful response with
        // an expected slug error is not treated as an API failure and will
        // display Formik validations as usual.
        throw errors;
      } else {
        handleAPIFailure("SlugValidationFailure", errors, dispatch);
      }
    });
};

export const validateAccountForm = ({
  firstName,
  lastName,
  email,
  phoneCountryCode
}) => {
  const errors = {};

  if (!firstName) {
    errors.firstName = requiredError;
  }

  if (!lastName) {
    errors.lastName = requiredError;
  }

  if (!email) {
    errors.email = requiredError;
  } else if (
    !/^['A-Z0-9_.%+-=]+@(?:[A-Z0-9-]+.)+(?:[A-Z]+)$/i.test(email) ||
    !parse(email.split("@")[1]).listed
  ) {
    errors.email = "forms.errors.email_format";
  }

  if (!phoneCountryCode) {
    errors.phoneCountryCode = requiredError;
  }

  return errors;
};
