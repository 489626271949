import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Logo from "./Logo";
import { useStateValue } from "../hooks/state";


const CheckEmail = () => {
  const { t } = useTranslation();
  const [state] = useStateValue();
  const dialogRef = useRef(null);

  useEffect(() => {
    dialogRef.current.showModal();

    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <div style={{ height: '100vh', overflow: 'hidden' }}>
      <div className="check-email-background" />
      <dialog ref={dialogRef} inert>
        <div className="d-flex justify-content-center">
          <Logo />
        </div>
        <div className="d-flex justify-content-center">
          <h1>{t('components.check_email.verify_your_email')}</h1>
        </div>
        <div className="d-flex justify-content-center text-center" style={{ maxWidth: '500px' }}>
          <p>
            {t('components.check_email.verify_your_email_body_start')}
            {' '}
            <strong>{state.userData.email}</strong>
            {' '}
            {t('components.check_email.verify_your_email_body_end')}
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <p>{t('components.check_email.verify_your_email_footer')}</p>
        </div>
      </dialog>
    </div>
  );
};

export default CheckEmail;
