import { CREATE_STATUS } from "../api";
import honeybadger from "./honeybadger";
import { redirect } from "./utils";

const baseError = (errors, searchString) =>
  errors &&
  Array.isArray(errors.base) &&
  errors.base.find(error => error.includes(searchString));

export const handleCreationResponse = (response, state, dispatch) => {
  const { nation, errors } = response;
  const paymentError = baseError(errors, "payment");

  if (nation) {
    try {
      if (window.ga) {
        window.ga("send", "event", "Trial", "Signup", "Get Started");
        window.dataLayer.push({ event: "Getting Started" });
      }
    } catch (error) {
      honeybadger.notify(error, {
        name: "GASendError",
        context: { slug: nation.slug }
      });
    }

    dispatch({ type: "network-success-received" });
  } else if (errors && errors.slug) {
    dispatch({
      type: "network-errors-received",
      errors,
      nextStepName: "slug"
    });
  } else if (paymentError) {
    dispatch({
      type: "network-errors-received",
      errors: { stripe: paymentError },
      nextStepName: "billing"
    });
  } else if (response.timeout) {
    honeybadger.notify({
      context: {
        slug: state.userData.slug,
        requestStartTime: response.requestStartTime
      },
      name: "NationCreationSuccessfulTimeout"
    });
    window.ga("send", "event", "Trial", "Signup", "Get Started");
    window.dataLayer.push({ event: "Getting Started" });
    dispatch({ type: "create-status", status: CREATE_STATUS.TIMEOUT });
  } else {
    handleAPIFailure("UnexpectedNationCreationResponse", response, dispatch);
  }
};

export const handleAPIFailure = (name, response, dispatch) => {
  honeybadger.notify({
    context: { response: JSON.stringify(response) },
    name
  });
  dispatch({
    type: "network-failure-received",
    serviceDown: true
  });
};

export const handleNbuildDataLoaded = (data, dispatch) => {
  if (data && data.sca_secret) {
    data.signup = data.signup || {};
    dispatch({ type: "nbuild-load-data", data });
  } else {
    honeybadger.notify({
      name: "ErrorLoadingInitialData"
    });
    dispatch({
      type: "network-failure-received",
      serviceDown: true
    });
  }
};

export const handlePricingInfoResponse = async (response, dispatch) => {
  try {
    let responseBody = await response.text();
    let data = JSON.parse(responseBody);

    if (data && data.trial_pricing_text) {
      dispatch({ type: "set-pricing-data", data });
    } else {
      sendAPIFailureNotification("GetTrialPricingFailure", response);
    }
  } catch(e) {
    sendAPIFailureNotification("GetTrialPricingFailure", response);
  }
};

export const redirectToNbuildStart = () => {
  honeybadger.notify({
    name: "MissingInitialNbuildData"
  });
  redirect(process.env.REACT_APP_NBUILD_START_URL);
};

// Notify team for API Requests that have failed
// does not mark service as down or halt trial submission
export const sendAPIFailureNotification = (name, response) => {
  honeybadger.notify({
    context: { response: JSON.stringify(response) },
    name
  });
};
