const networkReducer = (state, action) => {
  switch (action.type) {
    case "network-saving":
      return {
        ...state,
        saving: action.saving,
        errors: action.saving ? null : state.errors
      };
    case "network-errors-received":
      return {
        ...state,
        saving: false,
        errors: action.errors
      };
    case "network-failure-received":
      return {
        ...state,
        serviceDown: true,
        saving: false
      };
    case "network-success-received":
      return {
        ...state,
        createSuccess: true
      };
    case "create-status":
      return {
        ...state,
        createStatus: action.status
      };
    default:
      return state;
  }
};

export default networkReducer;
